import React from 'react';

function ErrorHandler({ error, errorType }) {
  if (errorType === 'formik') {
    return error ? <div className={'text-danger'}>{error}</div> : <div />;
  }

  if (errorType === 'server') {
    return error ? <div className={'text-danger'}>{error.message}</div> : <div />;
  }
}

export default ErrorHandler;
