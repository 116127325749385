import React from 'react';
import { Button, Form, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { userDataSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import NavBrand from 'sharedComponents/navbars/partials/NavBrand';

const Confirmusername = () => {
  const navigate = useNavigate();
  const { user } = useSelector(userDataSelector);

  const isActive = user.plan.subscriptionStatus === 'active'

  const handleContinueClick = () => {
    navigate(isActive ? '/upgraded' : '/upgrade');
  };

  return (
    <>
      <div className="container-fluid">
        <nav
          id="navbar-main"
          className="navbar-horizontal navbar-main navbar navbar-expand-lg justify-content-left row p-0 zindex-1"
        >
          <Col className="col-lg-12 col-sm-12 d-flex justify-content-center bg-white col p-2 shadow ">
            <NavBrand isTransparent={false} />
          </Col>
        </nav>

        <Row className="min-vh-100">
          <Col className="order-lg-2 col-lg-6 col-sm-12 d-sm-none flex-grow-1 d-lg-flex align-items-center justify-content-center">
            <div className="onboarding-image mw-600-sm">
              <img src={'images/image3.svg'} alt={'confirm username'} />
            </div>
          </Col>

          <Col className="order-lg-1 col-lg-6 col-sm-12 flex-grow-1 d-flex align-items-center justify-content-center bg-white">
            <Form role="form" className="mw-500-sm">
              <Row>
                <Col className="col-sm-12 d-lg-block">
                  <h1 className="h1 text-center">Welcome {user?.username || 'New user'}!</h1>
                  <p className="text-center">
                    Connect your calendar and start creating meetings.
                    <br />
                    You can change your username anytime.
                  </p>
                  <Button type="button" color="primary" size="xl" block onClick={handleContinueClick}>
                    Continue
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    size="xl"
                    className="btn-link bg-transparent border-0 text-muted"
                    block
                    onClick={() => navigate('/changeusername')}
                  >
                    Change Username
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <div className="wrapper">
          <div className="confetti-149" />
          <div className="confetti-148" />
          <div className="confetti-147" />
          <div className="confetti-146" />
          <div className="confetti-145" />
          <div className="confetti-144" />
          <div className="confetti-143" />
          <div className="confetti-142" />
          <div className="confetti-141" />
          <div className="confetti-140" />
          <div className="confetti-139" />
          <div className="confetti-138" />
          <div className="confetti-137" />
          <div className="confetti-136" />
          <div className="confetti-135" />
          <div className="confetti-134" />
          <div className="confetti-133" />
          <div className="confetti-132" />
          <div className="confetti-131" />
          <div className="confetti-130" />
          <div className="confetti-129" />
          <div className="confetti-128" />
          <div className="confetti-127" />
          <div className="confetti-126" />
          <div className="confetti-125" />
          <div className="confetti-124" />
          <div className="confetti-123" />
          <div className="confetti-122" />
          <div className="confetti-121" />
          <div className="confetti-120" />
          <div className="confetti-119" />
          <div className="confetti-118" />
          <div className="confetti-117" />
          <div className="confetti-116" />
          <div className="confetti-115" />
          <div className="confetti-114" />
          <div className="confetti-113" />
          <div className="confetti-112" />
          <div className="confetti-111" />
          <div className="confetti-110" />
          <div className="confetti-109" />
          <div className="confetti-108" />
          <div className="confetti-107" />
          <div className="confetti-106" />
          <div className="confetti-105" />
          <div className="confetti-104" />
          <div className="confetti-103" />
          <div className="confetti-102" />
          <div className="confetti-101" />
          <div className="confetti-100" />
          <div className="confetti-99" />
          <div className="confetti-98" />
          <div className="confetti-97" />
          <div className="confetti-96" />
          <div className="confetti-95" />
          <div className="confetti-94" />
          <div className="confetti-93" />
          <div className="confetti-92" />
          <div className="confetti-91" />
          <div className="confetti-90" />
          <div className="confetti-89" />
          <div className="confetti-88" />
          <div className="confetti-87" />
          <div className="confetti-86" />
          <div className="confetti-85" />
          <div className="confetti-84" />
          <div className="confetti-83" />
          <div className="confetti-82" />
          <div className="confetti-81" />
          <div className="confetti-80" />
          <div className="confetti-79" />
          <div className="confetti-78" />
          <div className="confetti-77" />
          <div className="confetti-76" />
          <div className="confetti-75" />
          <div className="confetti-74" />
          <div className="confetti-73" />
          <div className="confetti-72" />
          <div className="confetti-71" />
          <div className="confetti-70" />
          <div className="confetti-69" />
          <div className="confetti-68" />
          <div className="confetti-67" />
          <div className="confetti-66" />
          <div className="confetti-65" />
          <div className="confetti-64" />
          <div className="confetti-63" />
          <div className="confetti-62" />
          <div className="confetti-61" />
          <div className="confetti-60" />
          <div className="confetti-59" />
          <div className="confetti-58" />
          <div className="confetti-57" />
          <div className="confetti-56" />
          <div className="confetti-55" />
          <div className="confetti-54" />
          <div className="confetti-53" />
          <div className="confetti-52" />
          <div className="confetti-51" />
          <div className="confetti-50" />
          <div className="confetti-49" />
          <div className="confetti-48" />
          <div className="confetti-47" />
          <div className="confetti-46" />
          <div className="confetti-45" />
          <div className="confetti-44" />
          <div className="confetti-43" />
          <div className="confetti-42" />
          <div className="confetti-41" />
          <div className="confetti-40" />
          <div className="confetti-39" />
          <div className="confetti-38" />
          <div className="confetti-37" />
          <div className="confetti-36" />
          <div className="confetti-35" />
          <div className="confetti-34" />
          <div className="confetti-33" />
          <div className="confetti-32" />
          <div className="confetti-31" />
          <div className="confetti-30" />
          <div className="confetti-29" />
          <div className="confetti-28" />
          <div className="confetti-27" />
          <div className="confetti-26" />
          <div className="confetti-25" />
          <div className="confetti-24" />
          <div className="confetti-23" />
          <div className="confetti-22" />
          <div className="confetti-21" />
          <div className="confetti-20" />
          <div className="confetti-19" />
          <div className="confetti-18" />
          <div className="confetti-17" />
          <div className="confetti-16" />
          <div className="confetti-15" />
          <div className="confetti-14" />
          <div className="confetti-13" />
          <div className="confetti-12" />
          <div className="confetti-11" />
          <div className="confetti-10" />
          <div className="confetti-9" />
          <div className="confetti-8" />
          <div className="confetti-7" />
          <div className="confetti-6" />
          <div className="confetti-5" />
          <div className="confetti-4" />
          <div className="confetti-3" />
          <div className="confetti-2" />
          <div className="confetti-1" />
          <div className="confetti-0" />
        </div>
      </div>
    </>
  );
};

export default Confirmusername;
