import { zeroPad } from 'helpers';
import { v4 as uuidv4 } from 'uuid';
import { convertFromDateToAmPmFormat, getTimeFromTimezone } from '../helpers/time';

export const meetingType = [
  {
    name: 'oneOnOne',
    title: '1-On-1 Appointment',
    description: 'A private appointment that happens once.',
    isGroupMeeting: false,
  },
  {
    name: 'group',
    title: 'Group Appointment',
    description: 'For 3+ participants and happens once.',
    isGroupMeeting: true,
  },

  /*this meeting types will add to version higher than 1.0v*/
  // {
  //   id: 'oneByOneRegularly',
  //   name: 'Recurring 1-On-1 Meetings',
  //   description: 'A private meeting that happens regularly (every week, every month etc.)',
  //   type: OneOnOneType,
  //   isOnceMeeting: false,
  // },
  // {
  //   id: 'groupOnceRegularly',
  //   name: 'Recurring Group Meetings',
  //   description: 'For 3+ participants and happens regularly (every week, every month etc.)',
  //   type: groupType,
  //   isOnceMeeting: false,
  // }
];

export const createNewTitle = (sessionTime) => {
  if (sessionTime === 1) {
    return `${sessionTime} Hour Appointment`;
  }
  if (sessionTime < 10) {
    return `${sessionTime} Hours Appointment`;
  }
  return `${sessionTime} Minutes Appointment`;
};

export const landingOptionId = 'LandingPage';
export const bookingOptionId = 'BookingPage';

export const meetingOptions = [
  {
    id: landingOptionId,
    name: 'A Landing Page',
    description: 'Create a landing page for your appointment and offer your customers to book a time with you.',
  },
  {
    id: bookingOptionId,
    name: 'Booking Only',
    description: 'Let your customers book an appointment from your website or through a custom link.',
  },
];

export const zoomId = 'zoom';
export const googleMeetId = 'google-meet';
export const inPersonMeetingId = 'in-person';
export const customSolutionId = 'custom';

export const noLinkAvalable = 'no-link-avalable';
export const noLinkUrl = 'https://dev.weshare.net'; //default link to prevent location URL error
{
  /* { name: 'Zoom', id: zoomId, isHideOnAddLocationPage: false }, */
}
export const meetingLocation = [
  { name: 'Google Meet', id: googleMeetId, isHideOnAddLocationPage: false },
  { name: 'In-person Meeting', id: inPersonMeetingId, isHideOnAddLocationPage: false },
  { name: 'Custom Solution', id: customSolutionId, isHideOnAddLocationPage: false },
  { name: 'No link avalable', id: noLinkAvalable, isHideOnAddLocationPage: true },
];

export const timeArr = [
  10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 1, 75, 1.5, 105, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
  9.5,
];

export const amountArr = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 8, 27, 28, 29, 30,
];

export const participantsArr = new Array(99).fill('').map((_, index) => index + 2);

export const priceArr = new Array(202).fill('').map((_, index) => {
  if (index === 0) return 1;
  if (index === 1) return 3;
  return (index - 1) * 5;
});

export const initialBenefits = [{ name: '24/7 Availability', isBasicChecked: false, isSubscriptionChecked: false }];

export const initialThankYouPageValue = {
  title: 'Thank You!',
  text: `<p>Your appointment booking was successful.</p>
  <p>We're looking forward to meeting you :)</p>
  <p>Appointment Details:</p>
  <ul>
  <li>Title: {meetingTitle}</li>
  <li>Date: {meetingDate}</li>
  <li>Time: {meetingTime}</li>
  <li>With: {vendorName}</li>
  <li>Location: {meetingLocation}</li>
  </ul>
  <p>The best way to get reminders to your appointment is by adding it to your calendar:</p>`,
};

export const initialBasicPackage = {
  idName: 'basic',
  name: 'Book an appointment👇',
  description: '',
  price: 0,
};

export const initialSubscriptionPackage = {
  idName: 'subscription',
  name: 'Subscription',
  description: '',
  price: 15,
};

export const timeZones = [
  {
    groupName: 'Etc',
    groupData: [
      { offset: 'GMT-12:00', timezone: 'Etc/GMT-12', name: 'GMT-12' },
      { offset: 'GMT-11:00', timezone: 'Etc/GMT-11', name: 'GMT-11' },
      { offset: 'GMT+08:00', timezone: 'Etc/GMT+8', name: 'GMT+8' },
      { offset: 'GMT+10:00', timezone: 'Etc/GMT+10', name: 'GMT+10' },
      { offset: 'GMT+12:00', timezone: 'Etc/GMT+12', name: 'GMT+12' },
    ],
  },
  {
    groupName: 'US & Canada',
    groupData: [
      { offset: 'GMT-09:00', timezone: 'America/Anchorage', name: 'Alaska Time' },
      { offset: 'GMT-08:00', timezone: 'America/Los_Angeles', name: 'Pacific Time' },
      { offset: 'GMT-07:00', timezone: 'America/Yellowknife', name: 'Mountain Time' },
      { offset: 'GMT-06:00', timezone: 'America/Matamoros', name: 'Central Time' },
      { offset: 'GMT-05:00', timezone: 'America/Cancun', name: 'Eastern Time' },
      { offset: 'GMT-09:00', timezone: 'Pacific/Honolulu', name: 'Hawaii Time' },
    ],
  },
  {
    groupName: 'America',
    groupData: [
      { offset: 'GMT-10:00', timezone: 'America/Adak', name: 'Adak Time' },
      { offset: 'GMT-09:00', timezone: 'America/Anchorage', name: 'Anchorage Time' },
      { offset: 'GMT-08:00', timezone: 'America/Dawson_Creek', name: 'Dawson Creek Time' },
      { offset: 'GMT-08:00', timezone: 'America/Ensenada', name: 'Ensenada Time' },
      { offset: 'GMT-08:00', timezone: 'America/Los_Angeles', name: 'Los Angeles Time' },
      { offset: 'GMT-07:00', timezone: 'America/Chihuahua', name: 'Chihuahua Time' },
      { offset: 'GMT-07:00', timezone: 'America/Denver', name: 'Denver Time' },
      { offset: 'GMT-06:00', timezone: 'America/Belize', name: 'Belize Time' },
      { offset: 'GMT-06:00', timezone: 'America/Cancun', name: 'Cancun Time' },
      { offset: 'GMT-06:00', timezone: 'America/Chicago', name: 'Chicago Time' },
      { offset: 'GMT-05:00', timezone: 'America/Bogota', name: 'Bogota Time' },
      { offset: 'GMT-05:00', timezone: 'America/New_York', name: 'New York Time' },
      { offset: 'GMT-05:00', timezone: 'America/Havana', name: 'Havana Time' },
      { offset: 'GMT-04:30', timezone: 'America/Caracas', name: 'Caracas Time' },
      { offset: 'GMT-04:00', timezone: 'America/Campo_Grande', name: 'Campo Grande Time' },
      { offset: 'GMT-04:00', timezone: 'America/Glace_Bay', name: 'Glace Bay Time' },
      { offset: 'GMT-04:00', timezone: 'America/Goose_Bay', name: 'Goose Bay Time' },
      { offset: 'GMT-04:00', timezone: 'America/Santiago', name: 'Santiago Time' },
      { offset: 'GMT-04:00', timezone: 'America/La_Paz', name: 'La Paz Time' },
      { offset: 'GMT-03:00', timezone: 'America/Montevideo', name: 'Montevideo Time' },
      { offset: 'GMT-03:00', timezone: 'America/Araguaina', name: 'Araguaina Time' },
      { offset: 'GMT-03:00', timezone: 'America/Godthab', name: 'Godthab Time' },
      { offset: 'GMT-03:00', timezone: 'America/Miquelon', name: 'Miquelon Time' },
      { offset: 'GMT-03:00', timezone: 'America/Sao_Paulo', name: 'Sao Paulo Time' },
      { offset: 'GMT-03:30', timezone: 'America/St_Johns', name: 'St. Johns Time' },
      { offset: 'GMT-02:00', timezone: 'America/Noronha', name: 'Noronha Time' },
    ],
  },
  {
    groupName: 'Asia',
    groupData: [
      { offset: 'GMT+02:00', timezone: 'Asia/Beirut', name: 'Beirut Time' },
      { offset: 'GMT+02:00', timezone: 'Asia/Damascus', name: 'Damascus Time' },
      { offset: 'GMT+02:00', timezone: 'Asia/Gaza', name: 'Gaza Time' },
      { offset: 'GMT+02:00', timezone: 'Asia/Jerusalem', name: 'Jerusalem Time' },
      { offset: 'GMT+03:00', timezone: 'Asia/Riyadh', name: 'Riyadh Time' },
      { offset: 'GMT+03:30', timezone: 'Asia/Tehran', name: 'Tehran Time' },
      { offset: 'GMT+04:00', timezone: 'Asia/Dubai', name: 'Dubai Time' },
      { offset: 'GMT+04:00', timezone: 'Asia/Yerevan', name: 'Yerevan Time' },
      { offset: 'GMT+04:30', timezone: 'Asia/Kabul', name: 'Kabul Time' },
      { offset: 'GMT+05:00', timezone: 'Asia/Tashkent', name: 'Tashkent Time' },
      { offset: 'GMT+05:30', timezone: 'Asia/Kolkata', name: 'Kolkata Time' },
      { offset: 'GMT+05:45', timezone: 'Asia/Katmandu', name: 'Katmandu Time' },
      { offset: 'GMT+06:00', timezone: 'Asia/Dhaka', name: 'Dhaka Time' },
      { offset: 'GMT+06:00', timezone: 'Asia/Yekaterinburg', name: 'Yekaterinburg Time' },
      { offset: 'GMT+06:30', timezone: 'Asia/Rangoon', name: 'Rangoon Time' },
      { offset: 'GMT+07:00', timezone: 'Asia/Bangkok', name: 'Bangkok Time' },
      { offset: 'GMT+07:00', timezone: 'Asia/Novosibirsk', name: 'Novosibirsk Time' },
      { offset: 'GMT+08:00', timezone: 'Asia/Hong_Kong', name: 'Hong Kong Time' },
      { offset: 'GMT+08:00', timezone: 'Asia/Krasnoyarsk', name: 'Krasnoyarsk Time' },
      { offset: 'GMT+09:00', timezone: 'Asia/Irkutsk', name: 'Irkutsk Time' },
      { offset: 'GMT+09:00', timezone: 'Asia/Seoul', name: 'Seoul Time' },
      { offset: 'GMT+09:00', timezone: 'Asia/Tokyo', name: 'Tokyo Time' },
      { offset: 'GMT+10:00', timezone: 'Asia/Yakutsk', name: 'Yakutsk Time' },
      { offset: 'GMT+11:00', timezone: 'Asia/Vladivostok', name: 'Vladivostok Time' },
      { offset: 'GMT+12:00', timezone: 'Asia/Anadyr', name: 'Anadyr Time' },
      { offset: 'GMT+12:00', timezone: 'Asia/Magadan', name: 'Magadan Time' },
    ],
  },
  {
    groupName: 'Chile',
    groupData: [{ offset: 'GMT-06:00', timezone: 'Chile/EasterIsland', name: 'EasterIsland Time' }],
  },
  {
    groupName: 'Argentina',
    groupData: [{ offset: 'GMT-03:00', timezone: 'America/Argentina/Buenos_Aires', name: 'Buenos Aires Time' }],
  },
  {
    groupName: 'Atlantic',
    groupData: [
      { offset: 'GMT-01:00', timezone: 'Atlantic/Cape_Verde', name: 'Cape Verde Time' },
      { offset: 'GMT+01:00', timezone: 'Atlantic/Azores', name: 'Azores Time' },
      { offset: 'GMT+01:00', timezone: 'Atlantic/Stanley', name: 'Stanley Time' },
    ],
  },
  {
    groupName: 'Europe',
    groupData: [
      { offset: 'GMT+00:00', timezone: 'Europe/Belfast', name: 'Belfast Time' },
      { offset: 'GMT+00:00', timezone: 'Europe/Dublin', name: 'Dublin Time' },
      { offset: 'GMT+00:00', timezone: 'Europe/Lisbon', name: 'Lisbon Time' },
      { offset: 'GMT+00:00', timezone: 'Europe/London', name: 'London Time' },
      { offset: 'GMT+01:00', timezone: 'Europe/Amsterdam', name: 'Amsterdam Time' },
      { offset: 'GMT+01:00', timezone: 'Europe/Belgrade', name: 'Belgrade Time' },
      { offset: 'GMT+01:00', timezone: 'Europe/Brussels', name: 'Brussels Time' },
      { offset: 'GMT+03:00', timezone: 'Europe/Minsk', name: 'Minsk Time' },
      { offset: 'GMT+04:00', timezone: 'Europe/Moscow', name: 'Moscow Time' },
    ],
  },
  {
    groupName: 'Africa',
    groupData: [
      { offset: 'GMT+00:00', timezone: 'Africa/Abidjan', name: 'Abidjan Time' },
      { offset: 'GMT+01:00', timezone: 'Africa/Algiers', name: 'Algiers Time' },
      { offset: 'GMT+01:00', timezone: 'Africa/Windhoek', name: 'Windhoek Time' },
      { offset: 'GMT+02:00', timezone: 'Africa/Cairo', name: 'Cairo Time' },
      { offset: 'GMT+02:00', timezone: 'Africa/Blantyre', name: 'Blantyre Time' },
      { offset: 'GMT+03:00', timezone: 'Africa/Addis_Ababa', name: 'Addis Ababa Time' },
    ],
  },
  { groupName: 'UTC', groupData: [{ offset: 'UTC', timezone: 'UTC', name: 'UTC Time' }] },
  {
    groupName: 'Australia',
    groupData: [
      { offset: 'GMT+08:00', timezone: 'Australia/Perth', name: 'Perth Time' },
      { offset: 'GMT+08:45', timezone: 'Australia/Eucla', name: 'Eucla Time' },
      { offset: 'GMT+09:30', timezone: 'Australia/Adelaide', name: 'Adelaide Time' },
      { offset: 'GMT+09:30', timezone: 'Australia/Darwin', name: 'Darwin Time' },
      { offset: 'GMT+10:00', timezone: 'Australia/Brisbane', name: 'Brisbane Time' },
      { offset: 'GMT+10:00', timezone: 'Australia/Hobart', name: 'Hobart Time' },
      { offset: 'GMT+10:30', timezone: 'Australia/Lord_Howe', name: 'Lord Howe Time' },
    ],
  },
  {
    groupName: 'Pacific',
    groupData: [
      { offset: 'GMT-11:00', timezone: 'Pacific/Midway', name: 'Midway Time' },
      { offset: 'GMT-09:00', timezone: 'Pacific/Gambier', name: 'Gambier Time' },
      { offset: 'GMT+09:30', timezone: 'Pacific/Marquesas', name: 'Marquesas Time' },
      { offset: 'GMT+11:30', timezone: 'Pacific/Norfolk', name: 'Norfolk Time' },
      { offset: 'GMT+12:00', timezone: 'Pacific/Auckland', name: 'Auckland Time' },
      { offset: 'GMT+12:45', timezone: 'Pacific/Chatham', name: 'Chatham Time' },
      { offset: 'GMT+13:00', timezone: 'Pacific/Tongatapu', name: 'Tongatapu Time' },
      { offset: 'GMT+14:00', timezone: 'Pacific/Kiritimati', name: 'Kiritimati Time' },
    ],
  },
];
export const timeHours = new Array(12).fill('').map((_, index) => zeroPad(index + 1, 2));
export const timeMinutes = new Array(12).fill('').map((_, index) => zeroPad(index * 5, 2));
export const amPm = ['am', 'pm'];
export const timeByDefault = {
  from: convertFromDateToAmPmFormat(new Date(2021, 1, 1, 9, 0)),
  to: convertFromDateToAmPmFormat(new Date(2021, 1, 1, 17, 0)),
};
export const timeZoneDefault = {
  offset: new Date().getTimezoneOffset(),
  time: getTimeFromTimezone(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  name:
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.substring(Intl.DateTimeFormat().resolvedOptions().timeZone.lastIndexOf('/') + 1) + ' Time',
};

export const daysByDefault = [
  {
    id: 'MondayDay',
    nameOfDay: 'Monday',
    isAvailableDay: true,
    time: [timeByDefault],
    numberOfDay: 1,
  },

  {
    id: 'TuesdayDay',
    nameOfDay: 'Tuesday',
    isAvailableDay: true,
    time: [timeByDefault],
    numberOfDay: 2,
  },

  {
    id: 'WednesdayDay',
    nameOfDay: 'Wednesday',
    isAvailableDay: true,
    time: [timeByDefault],
    numberOfDay: 3,
  },

  {
    id: 'ThursdayDay',
    nameOfDay: 'Thursday',
    isAvailableDay: true,
    time: [timeByDefault],
    numberOfDay: 4,
  },

  {
    id: 'FridayDay',
    nameOfDay: 'Friday',
    isAvailableDay: true,
    time: [timeByDefault],
    numberOfDay: 5,
  },

  {
    id: 'SaturdayDay',
    nameOfDay: 'Saturday',
    isAvailableDay: false,
    time: [timeByDefault],
    numberOfDay: 6,
  },

  {
    id: 'SundayDay',
    nameOfDay: 'Sunday',
    isAvailableDay: false,
    time: [timeByDefault],
    numberOfDay: 7,
  },
];

//values below are in minutes
export const stepForCreateNewAvailableHoursRow = {
  from: 60,
  to: 120,
};
