import React from 'react';
import { Container, Row, Col, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const IndexFooter = () => {
  const navigate = useNavigate();
  let d = new Date();
  let currentYear = d.getFullYear();
  const goToAbout = () => {
    window.open('https://www.weshare.net/about-us/', '_blank');
  };
  const goToBlog = () => {
    window.open('https://www.weshare.net/blog', '_blank');
  };
  return (
    <>
      <Container className="justify-content-center pb-3 bg-white" fluid>
        <Row className="justify-content-center">
          <Col sm="auto">
            <NavLink onClick={goToAbout} className="font-weight-normal py-3 cursor-pointer">
              <span className="nav-link-inner--text small">About</span>
            </NavLink>
          </Col>
          <Col sm="auto">
            <NavLink
              href="https://www.weshare.net/terms-and-conditions/"
              className="font-weight-normal text-decoration-none py-3 cursor-pointer"
            >
              <span className="nav-link-inner--text small">Terms of Service</span>
            </NavLink>
          </Col>
          <Col sm="auto">
            <NavLink
              href="https://www.weshare.net/privacy-policy/"
              className="font-weight-normal text-decoration-none py-3 cursor-pointer"
            >
              <span className="nav-link-inner--text small">Privacy Policy</span>
            </NavLink>
          </Col>
          <Col sm="auto">
            <NavLink onClick={() => navigate('/security')} className="font-weight-normal py-3 cursor-pointer">
              <span className="nav-link-inner--text small">Security</span>
            </NavLink>
          </Col>
          <Col sm="auto">
            <NavLink onClick={goToBlog} className="font-weight-normal py-3 cursor-pointer">
              <span className="nav-link-inner--text small">Blog</span>
            </NavLink>
          </Col>
          <Col sm="12" md="12" lg="auto">
            <NavLink className="font-weight-normal py-3 cursor-pointer">
              <span className="nav-link-inner--text small">All Rights Reserved © {currentYear} Weshare Inc.</span>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default IndexFooter;
