import { gapi } from 'gapi-script';
import { clientId, clientSecret, apiKey, discoveryDocs, scope, redirect_uri } from 'config/googleConfig';
import axios from 'axios';

class GoogleApiService {
  static initClient() {
    gapi.load('client:auth2', this.getGoogleApiClient);
  }

  static async getGoogleApiClient() {
    await gapi.client.init({
      apiKey,
      clientId,
      scope,
      discoveryDocs,
      access_type: 'offline',
      response_type: 'code',
      redirect_uri,
    });
  }

  static async onAuth() {
    const GoogleAuth = gapi.auth2.getAuthInstance();
    GoogleAuth.signIn();

    const { code } = await GoogleAuth.grantOfflineAccess();

    const res = await axios.post('https://oauth2.googleapis.com/token', {
      code,
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: 'postmessage',
      grant_type: 'authorization_code',
    });

    return {
      googleAccessToken: res.data.access_token,
      googleRefreshToken: res.data.refresh_token || null,
    };
  }
}

export default GoogleApiService;
