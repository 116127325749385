import isEmpty from 'validator/lib/isEmpty';
import { each, omit } from 'lodash';

export function toUpperCaseFirstLetter(word) {
  return word.substr(0, 1).toUpperCase() + word.slice(1);
}

export function zeroPad(num, places) {
  return String(num).padStart(places, '0');
}

export function createMeetingEmbedCode(meetingRoute, color, type) {
  const src =
    meetingRoute && type !== 'all' && type !== 'popup-all'
      ? `${window.location.origin}/${meetingRoute}`
      : `${window.location.origin}/${meetingRoute.split('/')[0]}`;

  switch (type) {
    case 'mini':
      return `<iframe src="${src}?type=${type}&color=${color.substring(
        1,
      )}" width="100%" height="520px" frameborder="0" gesture="media" allow="encrypted-media"></iframe>`;

    case 'full':
      return `<iframe src="${src}?type=${type}&color=${color.substring(
        1,
      )}" width="100%" height="520px" frameborder="0" gesture="media" allow="encrypted-media"></iframe>`;

    case 'all':
      return `<iframe src="${src}?type=${type}&color=${color.substring(
        1,
      )}" width="100%" height="520px" frameborder="0" gesture="media" allow="encrypted-media"></iframe>`;

    case 'popup-single':
      return `<button style="position: fixed;bottom: 0;right: 20%;padding: 5px 35px;border: 0px;border-radius: 5px 5px 0 0;background:${color};color: #fff;font-weight: 600;box-shadow: 0 0 1.1rem 0 rgba(136, 152, 170, 0.45);height: 40px;line-height:24px;font-size:17px;z-index:100000;" onclick="document.getElementById('wsPopup').style.display = 'block'">Book A Call With Us</button><div id="wsPopup" style="position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(31, 31, 31, .4);z-index:100001;display:none;"><div style="position:absolute;left:0;right:0;bottom:0;top:0;cursor: pointer;" onclick="document.getElementById('wsPopup').style.display = 'none'"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" style="position:absolute;top:15px;right:15px;cursor:pointer;fill:#fff;"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg></div><iframe src="${src}?type=full&color=${color.substring(
        1,
      )}" frameborder="0" gesture="media" allow="encrypted-media" style="width: calc(100% - 78px);height: calc(100vh - 50px);position:absolute;top:50%;left:50%;transform: translate(-50%, -50%);max-width:750px;"></iframe></div>`;

    case 'popup-all':
      return `<button style="position: fixed;bottom: 0;right: 20%;padding: 5px 35px;border: 0px;border-radius: 5px 5px 0 0;background:${color};color: #fff;font-weight: 600;box-shadow: 0 0 1.1rem 0 rgba(136, 152, 170, 0.45);height: 40px;line-height:24px;font-size:17px;z-index:100000;" onclick="document.getElementById('wsPopup').style.display = 'block'">Book A Call With Us</button><div id="wsPopup" style="position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(31, 31, 31, .4);z-index:100001;display:none;"><div style="position:absolute;left:0;right:0;bottom:0;top:0;cursor: pointer;" onclick="document.getElementById('wsPopup').style.display = 'none'"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" style="position:absolute;top:15px;right:15px;cursor:pointer;fill:#fff;"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg></div><iframe src="${src}?type=full&color=${color.substring(
        1,
      )}" frameborder="0" gesture="media" allow="encrypted-media" style="width: calc(100% - 78px);height: calc(100vh - 50px);position:absolute;top:50%;left:50%;transform: translate(-50%, -50%);max-width:750px;"></iframe></div>`;

    default:
      return `<iframe src="${src}?type=mini" width="100%" height="520px" frameborder="0" gesture="media" allow="encrypted-media"></iframe>`;
  }
}

export function createMeetingBotEmbedCode(meetingRoute, color, character, textMessage) {
  const src = `${window.location.origin}/${meetingRoute}?type=bot&color=${color.substring(
    1,
  )}&img=${character}&text=${textMessage}`;
  return `<div id="wsPopup" style="position:fixed;bottom:0px;left:0px;z-index:1000000;"><iframe id="ws-bot" src="${src}" frameborder="0" gesture="media" allow="encrypted-media" style="width:470px;height:370px;" onload="close_frame()"></iframe></div><script>function close_frame(){console.log(window.should_close); if(!window.should_close){window.should_close=1;}else if(window.should_close==1){console.log(window.should_close); window.should_close=2; document.getElementById("ws-bot").style.width="80px"; document.getElementById("ws-bot").style.height="65px"; document.getElementById("wsPopup").style.bottom="205px";}else if(window.should_close===2){window.should_close=1; document.getElementById("ws-bot").style.width="470px"; document.getElementById("ws-bot").style.height="370px"; document.getElementById("wsPopup").style.bottom="0px";}}</script><style>@media(max-width:500px){#ws-bot{max-width:100%;}}</style>`;
}

export function changeGapOnDashOnInput(str) {
  const lastStrEl = str[str.length - 1];
  const prevLastStrEl = str[str.length - 2];

  if (lastStrEl === ' ' && prevLastStrEl !== '-') return str.replaceAll(/\s+/g, '-');
  if (lastStrEl === ' ') return str.trim();
  return str;
}

export function createMeetingRoute(username, meetingSlug) {
  return username && meetingSlug ? `${username}/${meetingSlug.replaceAll(/ /g, '-').toLowerCase()}` : '';
}

export function getSlugFromMeetingRoute(meetingRoute) {
  return meetingRoute?.split('/')[1];
}

export function findBasicPackage(meeting) {
  return meeting?.meetingPackages?.find((el) => el.idName === 'basic');
}

export function updateFieldInPackage(packages, packageId, fieldForUpdate, newFieldValue) {
  return packages.map((meetingPackage) => {
    if (meetingPackage.idName === packageId) {
      return {
        ...meetingPackage,
        [fieldForUpdate]: newFieldValue,
      };
    } else {
      return meetingPackage;
    }
  });
}

export function isEmptyIgnoreSpaces(str, config = {}) {
  return isEmpty(str, { ignore_whitespace: true, ...config });
}

export function removeFieldsFromObject(obj, fields) {
  const updatedObj = omit(obj, fields);

  each(updatedObj, function (val, key) {
    if (typeof val === 'object') {
      const res = removeFieldsFromObject(val, fields);
      updatedObj[key] = Array.isArray(val) ? Object.values(res) : res;
    }
  });

  return updatedObj;
}

export function onKeyDownEnterPreventDefault(keyEvent) {
  if (keyEvent.code === 'Enter') keyEvent.preventDefault();
}
