import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Loader from './sharedComponents/Loader';

function RestrictedRoute({ component: Component, ...rest }) {
  const isLoggedIn = useAuth();
  if (isLoggedIn === null) {
    return <Loader show={true} />;
  }

  return !isLoggedIn ? <Component {...rest} /> : <Navigate to={'/appointments'} />;
}

export default RestrictedRoute;
