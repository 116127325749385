import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useScript from 'hooks/useScript';
import Loader from './sharedComponents/Loader';
import useSubStatus from 'hooks/useSubStatus';
import usePaidStatus from 'hooks/usePaidStatus';
import Upgrade from './content/pages/Upgrade';
import Addname from './content/pages/onboarding/Addname';
import Confirmusername from './content/pages/onboarding/Confirmusername';
import Changeusername from './content/pages/onboarding/Changeusername';
import Confirmemail from './content/pages/onboarding/Confirmemail';
import { chatUrl, chatId, chatScenarioId } from 'constants/chatBotParams'

function PrivateRoute({ component: Component, ...rest }) {
  const isLoggedIn = useAuth();
  const isActiveStatus = useSubStatus();
  const { pathname } = useLocation();
  useScript(chatUrl, 'html', true, chatId, chatScenarioId, isActiveStatus);
  const isPaid = usePaidStatus();

  const onBoarding = {
    addname: <Addname />,
    confirmusername: <Confirmusername />,
    changeusername: <Changeusername />,
    confirmemail: <Confirmemail />,
    upgrade: <Upgrade />,
  };
  

  if (isLoggedIn === null) {
    return <Loader show={true} />;
  }

  if (isLoggedIn === false) {
    return <Navigate to={'/signup'} />;
  }

  if (!isActiveStatus) {
    const component = pathname.split('/')[1];
    return onBoarding[component] || <Navigate to={'/upgrade'} />;
  }
  
  if (!isPaid && pathname !== '/unpaid') {
    return <Navigate to={'/unpaid'} />;
  }

  return <Component {...rest} />;
}

export default PrivateRoute;
