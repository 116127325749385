import React, { useState } from 'react';
import AddNavbar from 'sharedComponents/navbars/AddNavbar';
import IndexFooter from 'sharedComponents/footers/IndexFooter';
import { useDispatch } from 'react-redux';
import 'moment-timezone';
import { ButtonGroup, Button, Card, CardHeader, CardBody, Row, Col, Container, CardFooter } from 'reactstrap';
import { createCheckoutSession } from '../../store/reducers/userReducer';

const Upgrade = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [numberOfUsers, setNumberOfUsers] = useState(1);
  const [pricePerUser, setPricePerUser] = useState(10);
  const pricePerUserMonthly = 15;

  const dispatch = useDispatch();

  const handleBillingCycleChange = (bool) => {
    setIsMonthly(bool);
    bool ? setPricePerUser(15) : setPricePerUser(10);
  };

  const handleStartTrial = async () => {
    try {
      const response = await dispatch(createCheckoutSession({ plan: isMonthly ? 'month' : 'year' }));
      window.location.replace(response.payload.url);
    } catch (error) {
      alert('You already have a plan, upgrade or downgrade');
    }
  };

  return (
    <>
      <div className="">
        <AddNavbar />
        <div className="bg-white">
          <Container className="py-3 px-3">
            <Row className="justify-content-center">
              <Col sm="12" className="mb-5 mt-3">
                <h1 className="display-2 text-center">Try it free for 5 days</h1>
                <h2 className="h2 font-weight-normal text-muted text-center">Start small and scale as you need.</h2>
              </Col>
              <Col sm="12" className="mb-3 d-flex justify-content-center">
                <ButtonGroup aria-label="Plan Duration">
                  <Button
                    variant="secondary"
                    className={isMonthly ? 'bg-premium text-white border-0' : ''}
                    onClick={() => handleBillingCycleChange(true)}
                  >
                    Billed Monthly
                  </Button>
                  <Button
                    variant="secondary"
                    className={isMonthly ? 'border-left' : 'bg-gradient-premium text-white border-0 border-left'}
                    onClick={() => handleBillingCycleChange(false)}
                  >
                    Billed Annually (33% OFF)
                  </Button>
                </ButtonGroup>
              </Col>
              <Col lg="10">
                <div className="pricing card-group flex-column flex-md-row mb-3">
                  <Card className="card-pricing bg-gradient-premium shadow-lg rounded-lg border-0 text-center mb-4 p-0 overflow-hidden mx-auto mw-500-sm">
                    <CardHeader className="bg-transparent">
                      <h2 className="ls-1 text-white py-2 mb-0">
                        <img
                          src="images/premium-white.svg"
                          alt=""
                          width="25"
                          style={{ height: '27.5px', verticalAlign: 'top' }}
                        />{' '}
                        <span style={{ verticalAlign: 'top' }}>Unlimited</span>
                      </h2>
                    </CardHeader>
                    <CardBody className="px-lg-7">
                      <div className="display-1 text-white">
                        <sup className="text-sm">US$</sup>
                        {pricePerUser}
                        <sub className="h3 text-white" style={{ bottom: '10px' }}>
                          /Month
                        </sub>
                      </div>

                      {!isMonthly ? (
                        <>
                          <span className="text-white">
                            Billed at <strike>${pricePerUserMonthly * numberOfUsers * 12}</strike> $
                            {pricePerUser * numberOfUsers * 12}/year
                          </span>
                        </>
                      ) : (
                        <></>
                      )}

                      <Button
                        className="btn-block btn-lg mt-3"
                        color="secondary"
                        type="button"
                        onClick={handleStartTrial}
                      >
                        Start Free Trial <i className="fas fa-arrow-right ml-1" />
                      </Button>
                      <p className="pt-2 text-white mb-0">
                        {' '}
                        <span>14-day Money Back Guarantee</span>
                      </p>
                      <ul className="list-unstyled my-4 text-left mw-400-sm">
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>Try It Free For 5 Days</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>Schedule Unlimited Appointments</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>Unlimited Lead Capturing</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>Automated Appointment Notifications</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>1-On-1 & Group Appointments</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>Integrations - Google Meet, Google Maps, Google Calendar etc.</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div>
                            <p className="text-white mb-0">
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="18"
                                height="26"
                                fill="#ffffff"
                                className="mr-1"
                                style={{ verticalAlign: 'top' }}
                              >
                                <path
                                  data-brackets-id="76099"
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                ></path>
                              </svg>{' '}
                              <span>Landing Page Builder</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </CardBody>
                    <CardFooter className="bg-transparent">
                      <p className="text-white mb-0">
                        {' '}
                        <span>Scroll down to see full features list</span>
                      </p>
                    </CardFooter>
                  </Card>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-lg-center px-3 mb-3">
              <div>
                <div className="icon icon-shape bg-white shadow rounded-circle text-primary">
                  <img src="images/premium.svg" alt="" />
                </div>
              </div>
              <Col lg="6">
                <p className="">
                  <strong>Weshare Unlimited</strong> is best for businesses & individuals who need advanced scheduling
                  and lead generation capabilities.
                </p>
              </Col>
            </div>
            <Row className="row-grid justify-content-center">
              <div className="col-xl-12">
                <div className="table-container">
                  <table className="price-table font-family-5">
                    <tbody>
                      <tr className="no-left-border">
                        <td className="price-blank"></td>
                        <td className="price-table-popular"></td>
                      </tr>
                      <tr className="price-table-head no-left-border">
                        <td></td>

                        <td className="green-width ">
                          Unlimited
                          <br />
                          <small className="text-sm">All Features Unlocked</small>
                        </td>
                      </tr>
                      <tr className="price-table-head-pricing no-left-border border-bottom-0">
                        <td className="align-items-end"></td>

                        <td className="price">
                          {!isMonthly ? (
                            <>
                              <sup>US$</sup>
                              <span className="pro-price-monthly">{pricePerUser}</span>
                              /Month
                              <p className="text-sm mb-0 mt-2">
                                Billed at <strike>${pricePerUserMonthly * numberOfUsers * 12}</strike> $
                                {pricePerUser * numberOfUsers * 12}/year
                              </p>
                            </>
                          ) : (
                            <>
                              <sup>US$</sup>
                              <span className="pro-price-monthly">{pricePerUser}</span>
                              /Month{' '}
                              <p className="text-sm mb-0 mt-2">Billed at ${pricePerUser * numberOfUsers}/month</p>
                            </>
                          )}

                          <Button
                            className="btn-highlight btn-lg rounded bg-gradient-premium border-0 font-size-5 font-weight-bold text-white my-2 px-4"
                            onClick={handleStartTrial}
                          >
                            Start Free Trial <i className="fa fa-arrow-right ml-1" />
                          </Button>
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0">
                          <h4 className="text-muted mb-0">CORE FEATURES</h4>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Unlimited Appointment Pages{' '}
                            <div
                              className="tooltip-down"
                              data-title="Let clients book your services, appointments, classes, workshops &amp; more."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Calendar Connections{' '}
                            <div
                              className="tooltip-down"
                              data-title="Full featured Calendar that allows you to manage all your calendars from a single place."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>1 Calendar</td>
                      </tr>

                      {/*<tr>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-between">Remove Weshare ads
                                                <div className="tooltip-down" data-title="Remove &quot;Powered By Weshare&quot;
  ads and give your visitors a site experience that's entirely your own brand."><svg width="16" height="16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="#D8D6E3" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path>
                                                    </svg></div>
                                            </div>
                                        </td>
                                        
                                        
                                                        <td><svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18" fill="#52BA98"><path data-brackets-id="76099" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></td>
                                    </tr>*/}
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Schedule Unlimited Appointments{' '}
                            <div
                              className="tooltip-down"
                              data-title="Allow your customers to schedule appointments on your calendar without limitations."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>

                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Custom Landing Page with unique URL{' '}
                            <div
                              className="tooltip-down"
                              data-title="Reach out to new customers with a custom link that lets customers book an appointment with you."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            1-On-1 &amp; Group Appointment Types{' '}
                            <div
                              className="tooltip-down"
                              data-title="Create 1-on-1 sessions/private lessons or group workshops, events, webinars etc."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Unlimited Lead Capturing{' '}
                            <div
                              className="tooltip-down"
                              data-title="Capture lead's details and organize them in a table"
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Automated Appointment Reminders{' '}
                            <div
                              className="tooltip-down"
                              data-title="We make sure that your customers will actually show up on time! You can set up personalized reminders to ensure the appointment goes smoothly."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Customizable Thank You Page{' '}
                            <div
                              className="tooltip-down"
                              data-title="Customize your own thank you page that confirms the appointment. Add more details your customers should know about the appointment."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Landing Page Builder
                            <div
                              className="tooltip-down"
                              data-title="Create an appointment booking landing page. Add videos, images and text to make your landing page convert more visitors."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Customers Management{' '}
                            <div
                              className="tooltip-down"
                              data-title="Save all your customers details in a table. Export any data to a CSV file at any time."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Automatic Time Zone Conversion{' '}
                            <div
                              className="tooltip-down"
                              data-title="Allow customers from different parts of the world to book a time with you and show them when you're available automatically."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Re-scheduling &amp; Cancellations{' '}
                            <div
                              className="tooltip-down"
                              data-title="We will notify you for any changes in your schedule like overlapping, rescheduled or cancelled appointments."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">CUSTOMIZATION</h4>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Display your logo{' '}
                            <div
                              className="tooltip-down"
                              data-title="Have a dedicated profile with all of your appointment types, embed your profile on your site or share it with a custom URL."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>

                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Brand your booking widget with your colors and copy{' '}
                            <div
                              className="tooltip-down"
                              data-title="Change the color of your booking widget or chatbot and use Weshare as an extension of your brand."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>

                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">INTEGRATIONS</h4>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Google Calendar 2-Way Sync{' '}
                            <div
                              className="tooltip-down"
                              data-title="Sync your Google Calendar with your Weshare full featured calendar so you don't book an appointment twice."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>

                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Google Meet &amp; Google Maps Integrations{' '}
                            <div
                              className="tooltip-down"
                              data-title="Connect Google Meet, Google Maps or any other video conference software to your appointment type. Once a customer book an appointment with you - we'll generate a unique meeting automatically for you."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Website/CMS integrations{' '}
                            <div
                              className="tooltip-down"
                              data-title="Embed a booking widget or a booking chatbot on your site to enjoy appointment scheduling directly where you need it."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr className="border-left-0">
                        <td className="border-0 pt-4">
                          <h4 className="text-muted mb-0">SUPPORT</h4>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Email support{' '}
                            <div className="tooltip-down" data-title="Get a support response by emailing us.">
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            Knowledge Base Access{' '}
                            <div
                              className="tooltip-down"
                              data-title="Gain access to our knowledge base to find more answers to questions you might have."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            VIP Support{' '}
                            <div
                              className="tooltip-down"
                              data-title="Enjoy 24/7 priority support. Upgrade to a higher plan for VIP live chat support &amp; skip the line."
                            >
                              <svg
                                width="16"
                                height="16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#D8D6E3"
                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="18"
                            height="18"
                            fill="#52BA98"
                          >
                            <path
                              data-brackets-id="76099"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </td>
                      </tr> */}

                      <tr className="no-left-border">
                        <td></td>
                        <td className="price">
                          <Button
                            className="btn-highlight btn-lg rounded bg-gradient-premium border-0 font-size-5 font-weight-bold text-white my-2 px-4"
                            onClick={handleStartTrial}
                          >
                            Start Free Trial <i className="fa fa-arrow-right ml-1" />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Row>
            <div className="d-flex justify-content-lg-center mt-5">
              <Col lg="10" className="p-0">
                <h2 className="display-4">Frequantly Asked Questions:</h2>
                <div className="mb-5">
                  <h3>What is Weshare Unlimited?</h3>
                  <p>
                    Schedule your next appointment with ease! With Weshare's Unlimited plan, you can schedule one-on-one
                    or group appointments through customizable landing pages, capture leads and organize them in a CRM
                    and more. For best value we recommend a yearly plan that discounts payments by 33% OFF.
                  </p>
                </div>
                <div className="mb-5">
                  <h3>Can I try it first?</h3>
                  <p>
                    Yes! Unlock all Weshare's Unlimited features and if you decide it's not for you, you can cancel
                    within the first 19 days and receive a full refund - no questions asked.
                  </p>
                </div>
                <div className="mb-5">
                  <h3>Is Weshare a mobile or desktop app?</h3>
                  <p>
                    Weshare is an online app that you will access through your browser at weshare.net. Weshare is not on
                    the Apple App Store or Google Play. The online app is mobile friendly, so you can create appointment
                    pages (booking and landing pages) on-the-go from your phone or tablet.
                  </p>
                </div>

                <div className="mb-5">
                  <h3>Do you offer any discounted plans?</h3>
                  <p>Yes, we offer a 33% discount on annual plans, when they are paid upfront.</p>
                </div>
                <div className="mb-5">
                  <h3>Do you offer setup assistance?</h3>
                  <p>
                    Of course – our team is here to help you get started. We offer weekly webinars, video tutorials,
                    step-by-step guides and personal 1:1 set up sessions.
                  </p>
                </div>

                <div className="mb-5">
                  <h3>Will I be able to change my subscription in the future?</h3>
                  <p>Yes, you may change your subscription at any time from yearly to monthly & vice versa.</p>
                </div>
                <div className="mb-5">
                  <h3>How do I upgrade or downgrade?</h3>
                  <p>
                    Visit your billing page by clicking on your profile picture and going to Settings > Billing. From
                    there, you can change your plan and update your payment information.
                  </p>
                </div>
              </Col>
            </div>
          </Container>
        </div>
      </div>
      <IndexFooter></IndexFooter>
    </>
  );
};

export default Upgrade;
