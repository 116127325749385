import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import currentMeetingReducer from './reducers/currentMeetingReducer';
import meetingsReducer from './reducers/meetingsReducer';
import userReducer from './reducers/userReducer';
import ordersReducer from './reducers/ordersReducer';

export default configureStore({
  reducer: {
    authData: authReducer,
    currentMeetingData: currentMeetingReducer,
    meetingsData: meetingsReducer,
    userData: userReducer,
    ordersData: ordersReducer,
  },
});
