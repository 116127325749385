import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { asyncActionsCreator } from 'store/reducersHelper';
import { inPersonMeetingId, customSolutionId } from 'constants/createMeeting.const';
import * as api from 'services/api';

export const saveOrder = createAsyncThunk('orders/saveOrder', async (orderData) => await api.saveOrder(orderData));

export const loadOrdersByMeetingRoute = createAsyncThunk(
  'orders/getOrdersByMeetingRoute',
  async (meetingRoute) => await api.getOrdersByMeetingRoute(meetingRoute),
);

export const loadOrdersByUserId = createAsyncThunk(
  'orders/loadOrdersByUserId',
  async () => await api.getOrdersByUserId(),
);

export const loadOrdersByUsername = createAsyncThunk(
  'orders/loadOrdersByUsername',
  async (orderData) => await api.getOrdersByUsername(orderData),
);

export const loadNotifications = createAsyncThunk(
  'orders/loadNotifications',
  async () => await api.getAllNotifications(),
);

export const addNewCustomerToOrder = createAsyncThunk(
  'orders/addNewCustomerToOrder',
  async (orderData) => await api.addCustomerToOrder(orderData),
);

export const findOneOrder = createAsyncThunk('orders/findOneOrder', async (orderData) => api.findOneOrder(orderData));

export const updateOrderById = createAsyncThunk(
  'orders/updateOrderById',
  async (orderData) => await api.updateOrderFieldsById(orderData),
);

export const deleteOrderById = createAsyncThunk(
  'orders/deleteOrderById',
  async (orderId) => await api.deleteOrderById(orderId),
);

export const updateViewedNotifications = createAsyncThunk(
  'orders/updateViewedNotifications',
  async (notificationsId) => await api.updateViewedNotifications(notificationsId),
);

export const sendOverlappingOrdersNotifications = createAsyncThunk(
  'orders/sendOverlappingOrdersNotifications',
  async (ordersId) => await api.sendOverlappingOrdersNotifications(ordersId),
);

export const removeCustomerById = createAsyncThunk(
  'orders/removeCustomerById',
  async (orderData) => await api.removeCustomerById(orderData),
);
export const checkAvailabilityOfTime = createAsyncThunk(
  'orders/checkAvailabilityOfTime',
  async (checkTimeOrderData) => await api.checkAvailabilityOfTime(checkTimeOrderData),
);

export const paymentToMeeting = createAsyncThunk(
  'orders/paymentToMeeting',
  async (paymentData) => await api.paymentToMeeting(paymentData),
);
const initialState = {
  orders: [],
  currentOrder: null,
  isExistOrder: null,
  notifications: [],
  isTimeAvailable: true,
  isUpdateOrderSuccess: false,
};
export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setCurrentOrder: (state, { payload }) => {
      state.currentOrder = payload;
    },
    setExistOrder: (state, { payload }) => {
      state.isExistOrder = payload;
    },
    addOrder: (state, { payload }) => {
      state.orders.push(payload);
    },
    updateOrder: (state, { payload }) => {
      const index = state.orders.findIndex((order) => order._id === payload.updatedOrder._id);
      state.orders[index] = payload.updatedOrder;
    },
    updateOrderLocation: (state, { payload }) => {
      const { newOrderDate, orderId, newLocation } = payload;
      const index = state.orders.findIndex((order) => order._id === orderId);
      const currentOrder = state.orders[index];
      const { meetingLocation } = currentOrder.meetingId;

      currentOrder.orderDate = newOrderDate;
      if (meetingLocation.locationId === customSolutionId) {
        meetingLocation.customLocationUrl = newLocation;
      }
      if (meetingLocation.locationId === inPersonMeetingId) {
        meetingLocation.inPersonLocation = newLocation;
      }
    },
    updateNotificatios: (state, { payload }) => {
      state.notifications.push(payload);
    },
    setIsUpdateOrderSuccess: (state, { payload }) => {
      state.isUpdateOrderSuccess = payload;
    },
    setIsTimeAvailable: (state, { payload }) => {
      state.isTimeAvailable = payload;
    },
    resetOrders: () => initialState,
  },
  extraReducers: {
    ...asyncActionsCreator(saveOrder, 'saveOrder', {
      fulfilled: (state, { payload }) => {
        state.orders.push(payload.order);
        state.currentOrder = null;
        state.isExistOrder = null;
      },
    }),

    ...asyncActionsCreator(loadNotifications, 'loadNotifications', {
      fulfilled: (state, { payload }) => {
        state.notifications = payload;
      },
    }),

    ...asyncActionsCreator(addNewCustomerToOrder, 'addNewCustomerToOrder', {
      fulfilled: (state, { payload }) => {
        state.orders = state.orders.map((order) =>
          payload.updatedOrder._id === order._id ? payload.updatedOrder : order,
        );
        state.currentOrder = null;
        state.isExistOrder = null;
      },
    }),

    ...asyncActionsCreator(
      loadOrdersByMeetingRoute,
      'loadOrdersByMeetingRoute',
      {
        fulfilled: (state, { payload }) => {
          state.orders = payload.orders;
          state.isLoadOrdersByMeetingRouteLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      loadOrdersByUsername,
      'loadOrdersByUsername',
      {
        fulfilled: (state, { payload }) => {
          state.orders = payload.orders;
          state.isLoadOrdersByUsernameAndDayLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      loadOrdersByUserId,
      'loadOrdersByUserId',
      {
        fulfilled: (state, { payload }) => {
          state.orders = payload.orders;
          state.isLoadOrdersByUserIdLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      updateOrderById,
      'updateOrderById',
      {
        fulfilled: (state, { payload }) => {
          state.orders = state.orders.map((order) => {
            return order._id === payload.updatedOrder._id ? payload.updatedOrder : order;
          });
          state.isUpdateOrderByIdLoading = false;
          state.isUpdateOrderSuccess = true;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      deleteOrderById,
      'deleteOrderById',
      {
        fulfilled: (state, { payload }) => {
          state.orders = state.orders.filter((order) => order._id !== payload.deletedOrder._id);
          state.isDeleteOrderByIdLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(updateViewedNotifications, 'updateViewedNotifications', {
      fulfilled: (state, { payload }) => {
        state.notifications = payload;
      },
    }),
    ...asyncActionsCreator(findOneOrder, 'findOneOrder', {}, { loadingHandler: true }),

    ...asyncActionsCreator(
      removeCustomerById,
      'removeCustomerById',
      {
        fulfilled: (state, { payload }) => {
          const indexOfUpdatedOrder = state.orders.findIndex((order) => order._id === payload.updatedOrder._id);
          state.orders[indexOfUpdatedOrder] = payload.updatedOrder;
          state.isRemoveCustomerByIdLoading = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(paymentToMeeting, 'paymentToMeeting', {
      fulfilled: (state, { payload }) => {
        if (payload.updatedOrder) {
          state.orders = state.orders.map((order) =>
            payload.updatedOrder._id === order._id ? payload.updatedOrder : order,
          );
          state.currentOrder = null;
          state.isExistOrder = null;
        }

        if (payload.order) {
          state.orders.push(payload.order);
          state.currentOrder = null;
          state.isExistOrder = null;
        }
      },
    }),
  },
});

export const {
  setIsUpdateOrderSuccess,
  setCurrentOrder,
  setExistOrder,
  addOrder,
  updateOrder,
  updateOrdersSubcrioption,
  updateNotificatios,
  setIsTimeAvailable,
  resetOrders,
  updateOrderLocation,
} = ordersSlice.actions;

export default ordersSlice.reducer;
