const pageRoutesMessageBeforeReload = [
  '/addtype',
  '/addtitle',
  '/addoptions',
  '/addlocation',
  '/addduration',
  '/addprice',
  '/addpricing',
  '/addname',
  '/addavailablehours',
  '/addcover',
];

function addMessageBeforeReload() {
  window.onbeforeunload = () => {
    return 'Are you sure? Changes you made may not be saved';
  };
}

function removeMessageBeforeReload() {
  window.onbeforeunload = null;
}

export function checkNeedMessageBeforeReload(activeRoute) {
  if (pageRoutesMessageBeforeReload.includes(activeRoute)) {
    addMessageBeforeReload();
  } else {
    removeMessageBeforeReload();
  }
}
