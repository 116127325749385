const translation = {
  translation: {
    // login
    log_in: 'Log in',
    login_to_your_account: 'Log in to your account',
    login_failed: 'Login Failed',
    forgot_password: 'Forgot password?',
    new_to_weshare_create_account: 'New to Weshare? Create an account',
    login_with_google: 'Login with Google',
    login_with_linkedin: 'Login with LinkedIn',
    login_with_facebook: 'Login with Facebook',

    // signup
    signup: 'Sign up',
    get_started_its_free: "Get started, it's free!",
    create_your_account: 'Create your account',

    linkedin: 'Linkedin',
    google: 'Google',
    facebook: 'Facebook',
    or: 'OR',
    email_address: 'Email Address',
    type_your_email_address: 'Type your email address',
    password: 'Password',
    type_your_password: 'Type your password',
  },
};

export default translation;
