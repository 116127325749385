import React, { useEffect } from 'react';
import { Button, FormGroup, Form, Input, InputGroup, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isLength from 'validator/es/lib/isLength';
import { userDataSelector } from 'store/selectors';
import { useFormik } from 'formik';
import { editAccountInformation } from 'store/reducers/userReducer';
import NavBrand from 'sharedComponents/navbars/partials/NavBrand';
import ErrorHandler from 'sharedComponents/ErrorHandler';
import Loader from 'sharedComponents/Loader';

const Addname = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSelector = useSelector(userDataSelector);
  const { user } = userSelector

  useEffect(() => {
    window.fpr("referral",{ email: user.primaryEmail });
  }, [])

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = t('Required');
    } else if (!isLength(values.fullName, { min: 3, max: 30 })) {
      errors.fullName = t('Must be a more than 3 and less than 30');
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      fullName: '',
    },
    onSubmit: async ({ fullName }) => {
      dispatch(
        editAccountInformation({
          fullName,
          username: fullName,
        }),
      );
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate,
  });

  useEffect(() => {
    if (userSelector.isSuccessEditAccountInfo) {
      navigate('/confirmusername');
    }
  }, [userSelector.isSuccessEditAccountInfo]);

  return (
    <>
        <div className="container-fluid">
          <nav
            id="navbar-main"
            className="navbar-horizontal navbar-main navbar navbar-expand-lg justify-content-left row p-0 zindex-1"
          >
            <Col className="col-lg-12 col-sm-12 d-flex justify-content-center bg-white col p-2 shadow ">
              <NavBrand isTransparent={false} />
            </Col>
          </nav>

          <Row className="min-vh-100">
            <Col className="order-lg-2 col-lg-6 col-sm-12 d-sm-none flex-grow-1 d-lg-flex align-items-center justify-content-center">
              <div className="onboarding-image mw-600-sm">
                <img src={'images/image2.svg'} alt={'img'} />
              </div>
            </Col>

            <Col className="order-lg-1 col-lg-6 col-sm-12 flex-grow-1 d-flex align-items-center justify-content-center bg-white">
              <Form role="form" className="mw-500-sm" onSubmit={formik.handleSubmit}>
                <Row>
                  <Col className="col-sm-12 d-lg-block">
                    <h1 className="h1 text-center">Add Your Company Name</h1>
                    <p className="text-center">Add your company name so customers can find you</p>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          id="input-brand-name"
                          className="form-control"
                          placeholder="Type your name"
                          type="text"
                          autoComplete="fullName"
                          name="fullName"
                          bsSize="lg"
                          value={formik.values.fullName}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      <ErrorHandler error={formik.errors.fullName} errorType={'formik'} />
                      <ErrorHandler error={userSelector?.editAccountInformationError} errorType={'server'} />
                      {formik.errors.fullName ? <div style={{ color: 'red' }}>{formik.errors.fullName}</div> : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="">
                  <ErrorHandler error={formik.errors.other} errorType={'formik'} />
                  <Button type="submit" color="primary" size="xl" block>
                    Continue
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
     
    </>
  );
};

export default Addname;
