import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { asyncActionsCreator } from 'store/reducersHelper';
import * as api from 'services/api';

export const addGoogleCalendarPermission = createAsyncThunk(
  'user/addGoogleCalendarPermission',
  async (tokens) => await api.addGoogleCalendarPermission(tokens),
);

export const updateUserTimeZone = createAsyncThunk(
  'user/updateUserTimezone',
  async (timeZone) => await api.updateUserTimeZone({ timeZone }),
);

export const editAccountInformation = createAsyncThunk(
  'user/editAccountInformation',
  async (editInfo) => await api.editAccountInformation(editInfo),
);

export const checkUserName = createAsyncThunk(
  'user/checkUserName',
  async (username) => await api.checkUserName(username),
);

export const checkEmail = createAsyncThunk('user/checkEmail', async (email) => await api.checkEmail(email)); // rewrite signup up after move to toolkit

export const checkForgotPasswordToken = createAsyncThunk(
  'user/checkForgotPasswordToken',
  async (token) => await api.checkForgotPasswordToken(token),
);

export const refreshToken = createAsyncThunk('user/refreshToken', async (token) => await api.refreshToken(token));

export const getUser = createAsyncThunk('user/getUser', async () => await api.getUser());

export const getUserByUserName = createAsyncThunk(
  'user/getUserByUserName',
  async (userName) => await api.getUserByUserName(userName),
);

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (email) => await api.forgotPassword(email));

export const changeUserPassword = createAsyncThunk(
  'user/changeUserPassword',
  async (email) => await api.changeUserPassword(email),
);

export const changeUserPasswordByOldPass = createAsyncThunk(
  'user/changeUserPasswordByOld',
  async (passwords) => await api.changeUserPasswordByOldPass(passwords),
);

export const loginWithEmail = createAsyncThunk(
  'user/loginWithEmail',
  async (userCredentials) => await api.loginWithEmail(userCredentials),
);

export const loginWithGoogle = createAsyncThunk(
  'user/loginWithGoogle',
  async (tokens) => await api.loginWithGoogle(tokens),
);

export const signupWithEmail = createAsyncThunk(
  'user/signupWithEmail',
  async (signUpData) => await api.signupWithEmail(signUpData),
);

export const registrationWithGoogle = createAsyncThunk(
  'user/registrationWithGoogle',
  async (registrationData) => await api.registrationWithGoogle(registrationData),
);

export const loginWithLinkedin = createAsyncThunk(
  'user/loginWithLinkedin',
  async (loginData) => await api.loginWithLinkedin(loginData),
);

export const loginWithFacebook = createAsyncThunk(
  'user/loginWithFacebook',
  async (loginData) => await api.loginWithFacebook(loginData),
);

export const findUserByEmail = createAsyncThunk(
  'user/findUserByEmail',
  async (email) => await api.findUserByEmail(email),
);

export const sendDeleteLink = createAsyncThunk('user/sendDeleteLink', async (email) => await api.sendDeleteLink(email));

export const getZoomCredentials = createAsyncThunk(
  'user/getZoomCredentials',
  async (code) => await api.getZoomCredentials(code),
);

export const disconnectZoom = createAsyncThunk('user/disconnectZoom', async () => await api.disconnectZoom());

export const toggleGoogleCalendar = createAsyncThunk(
  'user/toggleGoogleCalendar',
  async (data) => await api.toggleGoogleCalendar(data),
);

export const connectGoogleCalendar = createAsyncThunk(
  'user/connectGoogleCalendar',
  async (data) => await api.connectGoogleCalendar(data),
);

export const createCheckoutSession = createAsyncThunk(
  'user/connectGoogleCalendar',
  async (data) => await api.createCheckoutSession(data),
);

export const saveBillingPortalUrl = createAsyncThunk(
  'user/saveBillingPortalUrl',
  async (data) => await api.saveBillingPortalUrl(data),
);

export const getInvoiceUrl = createAsyncThunk('user/getInvoiceUrl', async () => await api.getInvoiceUrl());

export const toggleCancelSubscription = createAsyncThunk(
  'user/toggleCancelSubscription',
  async (data) => await api.toggleCancelSubscription(data),
);

export const upgradeSubscription = createAsyncThunk(
  'user/upgradeSubscription',
  async (data) => await api.upgradeSubscription(data),
);

export const getGoogleEvents = createAsyncThunk('user/getGoogleEvents', async () => await api.getGoogleEvents());

export const saveGoogleEvents = createAsyncThunk('user/saveGoogleEvents', async () => await api.saveGoogleEvents());
export const saveGoogleEventsByUserName = createAsyncThunk(
  'user/saveGoogleEventsByUserName',
  async (data) => await api.saveGoogleEventsByUserName(data),
);
const initialState = {
  isEditAccountInfoLoading: false,
  loginWithEmailError: null,
  forgotPasswordError: null,
  user: null,
  refreshToken: null,
  accessToken: null,
  isGoogleCalendarConnected: null,
  isUserCreateSuccessfulWithEmail: null,
  isUserCreateSuccessfulWithGoogle: null,
  isUserNameValid: true,
  isUserLoginSuccessful: null,
  isEmailValid: null,
  isPasswordTokenValid: null,
  isPasswordChange: null,
  isSuccessEditAccountInfo: null,
  googleCalendarEvents: [],
  calendarDate: new Date().toString(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    resetUser: () => initialState,

    resetError: (state, { payload }) => {
      state[payload] = null;
    },
    setIsNameValid: (state, { payload }) => {
      state.isUserNameValid = payload;
    },
    setCalendarDay: (state, { payload }) => {
      state.calendarDate = payload;
    },
    updateInvoiceStatus: (state, { payload }) => {
      state.user.invoiceData = payload;
    },
  },
  extraReducers: {
    ...asyncActionsCreator(addGoogleCalendarPermission, 'addGoogleCalendarPermission', {
      fulfilled: (state) => {
        state.isGoogleCalendarConnected = true;
      },
      rejected: (state, { error }) => {
        state.isLoading = false;
        state.addGoogleCalendarPermissionError = error;
      },
    }),

    ...asyncActionsCreator(
      editAccountInformation,
      'editAccountInfo',
      {
        fulfilled: (state, { payload }) => {
          state.isEditAccountInfoLoading = false;
          state.isSuccessEditAccountInfo = true;
          state.user = payload.user;
        },
        rejected: (state, { error }) => {
          state.isEditAccountInfoLoading = false;
          state.isSuccessEditAccountInfo = false;
          state.editAccountInformationError = error;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      checkUserName,
      'checkUserName',
      {
        fulfilled: (state, { payload }) => {
          state.isCheckUserNameLoading = false;
          state.isUserNameValid = payload.isUserNameValid;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      checkForgotPasswordToken,
      'checkForgotPasswordToken',
      {
        fulfilled: (state) => {
          state.isCheckForgotPasswordTokenLoading = false;
          state.isPasswordTokenValid = true;
        },
        rejected: (state, { error }) => {
          state.isCheckForgotPasswordTokenLoading = false;
          state.checkForgotPasswordTokenError = error;
          state.isPasswordTokenValid = false;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(checkEmail, 'checkEmail', {
      fulfilled: (state) => {
        state.isEmailValid = true;
      },
    }),

    ...asyncActionsCreator(changeUserPassword, 'changeUserPassword', {
      fulfilled: (state) => {
        state.isPasswordChange = true;
      },
    }),

    ...asyncActionsCreator(
      changeUserPasswordByOldPass,
      'changeUserPassword',
      {
        fulfilled: (state) => {
          state.isChangeUserPasswordLoading = false;
          state.isPasswordChange = true;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(
      getUser,
      'getUser',
      {
        fulfilled: (state, { payload }) => {
          state.isGetUserLoading = false;
          state.user = payload.user;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(forgotPassword, 'forgotPassword'),

    // not used action yet (не впевнений, що рефреш потрібно робити в саме в редаксі)
    ...asyncActionsCreator(refreshToken, 'refreshToken'),

    ...asyncActionsCreator(
      loginWithEmail,
      'loginWithEmail',
      {
        fulfilled: (state, { payload }) => {
          state.isLoginWithEmailLoading = false;
          state.user = payload.user;
          state.refreshToken = payload.refreshToken;
          state.accessToken = payload.accessToken;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(loginWithGoogle, 'loginWithGoogle', {
      fulfilled: (state, { payload }) => {
        state.refreshToken = payload.refreshToken;
        state.accessToken = payload.accessToken;
        state.user = payload.user;
        state.isUserLoginSuccessful = true;
      },
      rejected: (state) => {
        state.isUserLoginSuccessful = false;
      },
    }),

    ...asyncActionsCreator(signupWithEmail, 'signupWithEmail', {
      fulfilled: (state, { payload }) => {
        state.refreshToken = payload.refreshToken;
        state.accessToken = payload.accessToken;
        state.user = payload.user;
        state.isUserCreateSuccessfulWithEmail = true;
      },
    }),

    ...asyncActionsCreator(registrationWithGoogle, 'registrationWithGoogle', {
      fulfilled: (state, { payload }) => {
        state.user = payload.user;
        state.isUserCreateSuccessfulWithGoogle = true;
        state.refreshToken = payload.refreshToken;
        state.accessToken = payload.accessToken;
      },
    }),
    ...asyncActionsCreator(sendDeleteLink, 'sendDeleteLink', {
      rejected: (state, { error }) => {
        state.sendDeleteLink = error;
      },
    }),
    ...asyncActionsCreator(getZoomCredentials, 'getZoomCredentials', {
      fulfilled: (state, { payload }) => {
        state.user = payload;
      },
    }),
    ...asyncActionsCreator(disconnectZoom, 'disconnectZoom', {
      fulfilled: (state, { payload }) => {
        state.user = payload;
      },
    }),
    ...asyncActionsCreator(connectGoogleCalendar, 'connectGoogleCalendar', {
      fulfilled: (state, { payload }) => {
        state.user = payload.user;
        state.googleCalendarEvents = payload.events;
      },
    }),
    ...asyncActionsCreator(saveBillingPortalUrl, 'saveBillingPortalUrl', {
      fulfilled: (state, { payload }) => {
        state.user = payload.user;
      },
    }),

    ...asyncActionsCreator(toggleCancelSubscription, 'toggleCancelSubscription', {
      fulfilled: (state, { payload }) => {
        state.user = payload.user;
      },
    }),

    ...asyncActionsCreator(toggleGoogleCalendar, 'toggleGoogleCalendar', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
        if (payload.updateUser) {
          state.user = payload.updateUser;
        } else {
          state.user = payload.updatedUser;
        }
      },
    }),

    ...asyncActionsCreator(getGoogleEvents, 'getGoogleEvents', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload;
      },
    }),

    ...asyncActionsCreator(saveGoogleEvents, 'saveGoogleEvents', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
      },
    }),

    ...asyncActionsCreator(saveGoogleEventsByUserName, 'saveGoogleEventsByUserName', {
      fulfilled: (state, { payload }) => {
        state.googleCalendarEvents = payload.events;
      },
    }),

    // not used action yet
    ...asyncActionsCreator(loginWithLinkedin, 'loginWithLinkedin'),

    // not used action yet
    ...asyncActionsCreator(loginWithFacebook, 'loginWithFacebook'),
  },
});

export const { setUser, resetUser, resetError, setIsNameValid, setCalendarDay, updateInvoiceStatus } =
  userSlice.actions;

export default userSlice.reducer;
