import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { asyncActionsCreator } from 'store/reducersHelper';
import * as api from 'services/api';

export const loadMeetings = createAsyncThunk('meetings/loadMeetings', async () => await api.loadMeetings());

export const loadMeetingsByUserId = createAsyncThunk(
  'meetings/loadMeetingsByUserId',
  async (userId) => await api.loadAllAttachedMeetingsByUserId(userId),
);

export const deleteMeeting = createAsyncThunk(
  'meetings/deleteMeetingById',
  async (meetingId) => await api.deleteMeetingById(meetingId),
);

export const duplicateMeeting = createAsyncThunk(
  'meetings/duplicateMeeting',
  async (meetingData) => await api.saveMeeting(meetingData),
);

export const updateMeetings = createAsyncThunk('meetings/updateMeetings', async (meetingDataForUpdate) =>
  api.updateMeeting(meetingDataForUpdate),
);
const initialState = {
  meetings: null,
};
export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    addMeetingToState: (state, { payload }) => {
      state.meetings.push(payload);
    },
    updateMeetingInState: (state, { payload }) => {
      state.meetings = state.meetings.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }

        return item;
      });
    },
    resetMeetings: () => initialState,
  },

  extraReducers: {
    ...asyncActionsCreator(
      loadMeetings,
      'loadMeetings',
      {
        fulfilled: (state, { payload }) => {
          state.isLoadMeetingsLoading = false;
          state.meetings = payload.myMeetings;
        },
      },
      { loadingHandler: true },
    ),

    ...asyncActionsCreator(deleteMeeting, 'deleteMeeting', {
      fulfilled: (state, { payload }) => {
        const index = state.meetings.findIndex((item) => item._id === payload.meeting._id);
        state.meetings[index] = payload.meeting;
      },
    }),

    ...asyncActionsCreator(duplicateMeeting, 'duplicateMeeting', {
      fulfilled: (state, { payload }) => {
        state.meetings.push(payload.result);
      },
    }),

    ...asyncActionsCreator(updateMeetings, 'updateMeetings', {
      fulfilled: (state, { payload }) => {
        if (!isEmpty(state.meetings)) {
          state.meetings = state.meetings.map((item) => {
            if (item._id === payload.meeting._id) {
              return payload.meeting;
            }
            return item;
          });
        }
      },
    }),
  },
});

export const { addMeetingToState, updateMeetingInState, resetMeetings } = meetingsSlice.actions;

export default meetingsSlice.reducer;
