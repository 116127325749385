import { useEffect } from 'react'

const useScript = (url, selector = 'body', async = true, id, scenario_id, isActiveStatus) => {
  useEffect(() => {
    const fakeDiv = document.createElement('div')
    const element = document.querySelector(selector)
    const script = document.createElement('script')
    script.src = url
    script.async = async
    script.id = id;
    script.setAttribute("scenario_id", scenario_id);
    element.appendChild(isActiveStatus ? script : fakeDiv)
    return () => element.removeChild(isActiveStatus ? script : fakeDiv)
    
  }, [url, isActiveStatus])
}

export default useScript