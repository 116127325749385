import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { asyncActionsCreator } from 'store/reducersHelper';
import * as api from 'services/api';

export const sendVerificationCodeToEmail = createAsyncThunk(
  'auth/sendVerificationCodeToEmail',
  async () => await api.sendVerificationCode(),
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (verificationCode) => await api.verifyEmail(verificationCode),
);

export const checkRegistrationToken = createAsyncThunk(
  'auth/checkRegistrationToken',
  async (registrationToken) => await api.checkRegistrationToken(registrationToken),
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isEmailVerified: null,
    isAcceptRegistration: null,
  },
  reducers: {
    setAcceptRegistration: (state, { payload }) => {
      state.isAcceptRegistration = payload;
    },
    setError: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
  },
  extraReducers: {
    ...asyncActionsCreator(sendVerificationCodeToEmail, 'sendVerificationCodeToEmail'),

    ...asyncActionsCreator(verifyEmail, 'verifyEmail', {
      fulfilled: (state) => {
        state.isEmailVerified = true;
      },
    }),

    ...asyncActionsCreator(checkRegistrationToken, 'checkRegistrationToken', {
      pending: (state) => {
        state.isAcceptRegistration = null;
        state.checkRegistrationTokenError = null;
      },
      fulfilled: (state) => {
        state.isAcceptRegistration = true;
      },
      rejected: (state, { error }) => {
        state.isAcceptRegistration = false;
        state.checkRegistrationTokenError = error;
      },
    }),
  },
});

export const { setAcceptRegistration, setError } = authSlice.actions;

export default authSlice.reducer;
